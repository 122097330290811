<template>
    <div class="main-bloc">
      <HeaderSponsor class="d-none d-md-flex" />
      <HeaderSponsorPortable class="d-flex d-md-none" />
      
      <div class="containe">
        <div class="mt-15 prod-first-bloc">
          <h2 class="site-color">Our products and services</h2>
          <p>Because we want the best for you, we are offering assistance for companies who need feedback on their Pitch Deck or advice before fundraising.</p>
        </div>
  
        <div class="bg-light mt-10 justify-content-center">
          <div class="container">
            <div class="row col-gap justify-content-center">
              <div class="col-12 col-md-4 ms-lg-3 p-0 bg-info text-white">
                <div class="bg-info p-0 m-0">
                  <img src="../../assets/img/IdeasFundX_consultancy.png" alt="Consult our experts to have the winning strategy" class="w-100" />
                </div>
                <div class="p-3 descriptive-img">
                  <h3>Have a strategy in place to set yourself up for success</h3>
                </div>
              </div>

              <div class="col-12 col-md-6 ms-lg-3 p-6 bg-site text-left">
                <div class="p-2">
                  <p><strong class="normal-text">Consult our experts</strong></p>
                  <span class="normal-text">Questioning about your business?</span>
                  <ul>
                    <li class="normal-text">Scalability or Profitability</li>
                    <li>Go-to-Market Strategy B2B or B2C</li>
                    <li>Entry Barriers and CAPEX requirements</li>
                    <li>Customer Acquisition Cost vs Customer Lifetime Value...</li>
                  </ul>
                  <p class="normal-text">Know what you need to do now</p>
                  <p class="normal-text">30 or 60 minutes 1:1 consultations.</p>
                  <p class="normal-text">$150 / 30 min</p>
                  <div class="text-center d-block d-sm-none">
                    <img src="../../assets/img/showMore.png" alt="Show more" width="40" />
                  </div>
                </div>
              </div>
            </div>

            <!-- web -->
            <div class="d-none d-sm-block">
              <div class="d-flex align-items-around justify-content-around mt-7">
                <div>
                  <img src="../../assets/img/contact-consultancy.png" alt="contact-us-consultancy" width="50" class="cursor-pointer">
                  <p>1 <br> Contact Us <br> to schedule the call.</p>
                </div>
                <div>
                  <img src="../../assets/img/book-consultancy.png" alt="Book to consult us" width="50" class="cursor-pointer">
                  <p>2 <br> Book the day and time.</p>
                </div>
                <div>
                  <img src="../../assets/img/receive-invoice.png" alt="Receive invoice for consultancy" width="50" class="cursor-pointer">
                  <p>3 <br> Receive the invoice <br> and Pay to confirm <br> the booking.</p>
                </div>
                <div>
                  <img src="../../assets/img/appointment-consultancy.png" alt="Confirm consultancy appointment" width="50" class="cursor-pointer">
                  <p>4 <br> Receive the Appointment <br> Confirmation.</p>
                </div>
              </div>
            </div>

            <!-- mobile -->
            <div class="d-block d-sm-none hide-feedBackBloc">
              <div class="d-sm-flex align-items-around justify-content-around mt-7">
                <div class="row justify-content-around">
                  <div class="col-6">
                    <img src="../../assets/img/contact-consultancy.png" alt="contact-us-consultancy" width="50" class="cursor-pointer">
                    <p>1 <br> Contact Us <br> to schedule the call.</p>
                  </div>
                  <div class="col-6">
                    <img src="../../assets/img/book-consultancy.png" alt="Book to consult us" width="50" class="cursor-pointer">
                    <p>2 <br> Book the day <br> and time.</p>
                  </div>
                </div>
                <div class="row justify-content-around">
                  <div class="col-6">
                    <img src="../../assets/img/receive-invoice.png" alt="Receive invoice for consultancy" width="50" class="cursor-pointer">
                    <p>3 <br> Receive the invoice <br> and Pay to confirm <br> the booking.</p>
                  </div>
                  <div class="col-6">
                    <img src="../../assets/img/appointment-consultancy.png" alt="Confirm consultancy appointment" width="50" class="cursor-pointer">
                    <p>4 <br> Receive the Appointment <br> Confirmation.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-4 text-white" style="font-weight: bold; font-size: 18px; background-color: #514d7a;">Book a consultancy</div>
        <div class="bg-light pt-10">
          <div class="container">
            <v-row class="mb-n8">
              <v-col cols="12" sm="2">
                <FormInlineMessage class="text-danger" v-if="$v.consultancy_info.email.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">E-mail Address*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.consultancy_info.email.$error">
                  Please enter your email address
                </FormInlineMessage>
                <v-text-field
                  v-model="consultancy_info.email"
                  outlined
                  placeholder="Your Email Address"
                  dense
                  @blur="$v.consultancy_info.email.$touch()"
                />
              </v-col>
            </v-row>
  
            <v-row class="mb-n8">
              <v-col cols="12" sm="2">
                <FormInlineMessage v-if="$v.consultancy_info.name.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">Your name*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.consultancy_info.name.$error">
                  Please enter your name
                </FormInlineMessage>
                <v-text-field
                  v-model="consultancy_info.name"
                  outlined
                  placeholder="Your Name"
                  dense
                  @blur="$v.consultancy_info.name.$touch()"
                />
              </v-col>
            </v-row>
  
            <v-row class="mb-n8">
              <v-col cols="12" sm="2">
                <FormInlineMessage v-if="$v.consultancy_info.title.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">Title*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.consultancy_info.title.$error">
                  Please enter your title
                </FormInlineMessage>
                <v-text-field
                  v-model="consultancy_info.title"
                  outlined
                  placeholder="Your Title"
                  dense
                  @blur="$v.consultancy_info.title.$touch()"
                />
              </v-col>
            </v-row>
  
            <v-row class="mb-n8">
              <v-col cols="12" sm="2">
                <FormInlineMessage v-if="$v.consultancy_info.company.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">Company*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.consultancy_info.company.$error">
                  Please enter your company
                </FormInlineMessage>
                <v-text-field
                  v-model="consultancy_info.company"
                  outlined
                  placeholder="Company"
                  dense
                  @blur="$v.consultancy_info.company.$touch()"
                />
              </v-col>
            </v-row>
  
            <v-row>
              <v-col cols="12" sm="2">
                <FormInlineMessage v-if="$v.phoneValid.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">Phone*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.phoneValid.$error">
                  Please select valid phone number
                </FormInlineMessage>
                <vue-tel-input
                defaultCountry="country"
                v-model="consultancy_info.phone"
                @validate="validatePhone"/>
              </v-col>
            </v-row>
          </div>

          <div class="pb-3">
            <v-icon
              large
              color="#00aac3"
              right
              center
              @click.prevent.stop="addresBloc = true"
              v-if="!addresBloc"
            >
              mdi-arrow-down-circle
            </v-icon>
          </div>
        </div>

        <div class="bg-light mt-10" v-if="addresBloc">
          <div class="container">
            <AddressBlock @block3="getBillingAddress" class="w-100"/>
          </div>
        </div>
        <ConsultancyScheduleBlock 
          v-if="priceBlock"
          :product_price="300"
          :address="billing_address"
          :defaultCurrency=" true ?  'EUR' : 'USD'"
          :consultancy_info="consultancy_info"
          @orderSuccess="orderSuccess"
        />
      </div>
  
      <Footer />
    </div>
  </template>
  
  <script>
    import Footer from "@/components/FooterCms.vue";
    import HeaderSponsor from "@/components/HeaderSponsor.vue";
    import HeaderSponsorPortable from "@/components/HeaderSponsorPortable.vue";
    import {
      minLength,
      email,
      sameAs,
      required,
    } from "vuelidate/lib/validators";
    import AddressBlock from '../../views/products/AddressBlock.vue'
    import ConsultancyScheduleBlock from '../../views/products/ConsultancyScheduleBlock.vue';
  
  export default {
    components: {
      Footer,
      HeaderSponsor,
      HeaderSponsorPortable,
      AddressBlock,
      ConsultancyScheduleBlock
    },
    metaInfo: {
      title: 'IDEASFUNDX | Consult our experts for business success',
      meta: [
        {
          name: "description",
          content:
            "Consult our experts about your business and strategy.",
        },
        {
          name: "keywords",
          content:
            "secure fundraising, define your strategy, improve your business plan, organize your governance"
        },
        {
          property: "og:image",
          content: "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          property: "og:description",
          content: "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the deal flow screening process for high quality deals.",
        },
        {
          property: "og:url",
          content: "https://www.ideasfundx.com/",
        },
        {
          property: "og:site_name",
          content: "IdeasFundX",
        },
        {
          name: "twitter:card",
          content:
            "summary_large_image",
        },
        {
          name: "twitter:title",
          content:
            "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          name: "twitter:description",
          content:
            "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the dealflow screening process for high quality deals.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          name: "twitter:image:width",
          content:
            "1200",
        },
        {
          name: "twitter:image:height",
          content:
            "630",
        },
      ]
    },
    data() {
      return {
        addresBloc: false,
        priceBlock: false,
        consultancy_info : {
          email: null,
          name: null,
          title: null,
          phone: null,
          company: null,
        },
        phoneValid: false,
        billing_address: null,
      };
    },
  
    validations: {
      phoneValid: { sameAs: sameAs(() => true) },
      consultancy_info : {
        email: { required, email },
        name: { required, minLength: minLength(2) },
        title: { required },
        phone: { required },
        company: { required },
      },
    },
  
    methods: {
      getBillingAddress(payload) {
        this.billing_address = payload;
        this.priceBlock = true;
      },

      validatePhone(params) {
        this.phoneValid = params.valid;
        this.consultancy_info.phone = params.number;
      },

      orderSuccess() {
        this.addresBloc = false;
        this.priceBlock = false;
      },
    },
    mounted() {},
  };
  </script>
  
<style scoped>
  @import '../../assets/styles/products.css';
</style>
  