<template>
  <div>
    <v-sheet height="100%" class="mt-10">
      <div class="container">
        <v-row align="center" justify="center" class="pt-2">
          <v-col cols="1" />
          <v-col cols="12">
            <p class="pt-2 text-sm-left cyan--text text--darken-1" id="title">
              Your Billing and Payment Currency
            </p>
            <v-row>
              <v-col cols="12" sm="12" md="4" lg="4">
                <p class="text-left thin pt-2">
                  Confirm the currency of the payment
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-select
                  v-model="currency"
                  :items="currencies"
                  background-color="white"
                  @change="computerAmoutToBePayed()"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </div>
    </v-sheet>
  
    <v-sheet height="100%" class="mx-auto mt-10">
      <div class="container">
        <v-row align="center" justify="center" class="pt-2">
          <v-col cols="1" />
          <v-col cols="12">
            <p class="pt-2 text-sm-left cyan--text text--darken-1" id="title">
              Redeem your coupon
            </p>
            <v-row>
              <v-col cols="12" sm="12" md="4" lg="4">
                <p class="text-left thin pt-2">
                  Promo code
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                  <v-text-field
                    v-model="promo_code"
                    background-color="white"
                    :error="is_not_correct"
                    :success="is_correct"
                    outlined
                    dense
                    placeholder="Promo code"
                  />
              </v-col>
            </v-row>
              <p class="pt-2 text-sm-left blue-grey--text text--lighten-2 font-weight-thin" v-if="discount">SAVE: {{ discount.coupon_type == 'Percent' ? `${discount.percent_discount } %`: `USD ${discount.amount_discount}` }}</p>
            <v-col cols="12" v-if="message" class="text-left mt-n5 ml-n3">
              <div>{{ message }} Please <a :href="CMScontact">contact us</a> for more information</div>
            </v-col>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </div>
    </v-sheet>
  
    <v-sheet height="100%" class="mx-auto mt-10">
      <div class="container">
        <v-row align="center" justify="center" class="pt-2">
          <v-col cols="1" />
          <v-col cols="12" class="bloc-mt-md-n9">
            <p class="pt-2 text-sm-left cyan--text text--darken-1" id="title">
              Your Order
            </p>
            <v-row class="mb-2">
              <v-col cols="6" sm="6" md="4" lg="4">
                <p class="text-left"><strong>Get Feedback for your Pitch Deck</strong></p>
              </v-col>
              <v-col cols="6" sm="4" md="4" lg="3">
                <p style="border: 1px solid" class="px-4" v-if="vatBlock">
                  {{ currency }} {{ currency == "USD" ? TotalpriceUSD : TotalpriceEUR }}
                </p>
                <p style="border: 1px solid" class="px-4" v-else>
                  {{ currency }} {{ currency == "USD" ? priceUSD : priceEUR }}
                </p>
              </v-col>
            </v-row>
            <v-row class="mt-n10">
              <v-col cols="6" sm="6" md="4" lg="4">
                <p class="text-left thin">Discount</p>
              </v-col>
              <v-col cols="6" sm="4" md="4" lg="3" class="price" style="width: 9%">
                <p class="thin d-flex justify-content-end">
                  {{ currency }} {{ currency == "USD" ? discountValueUSD : discountValueEUR }}
                </p>
              </v-col>
            </v-row>
            <v-row class="mt-n10">
              <v-col cols="6" sm="6" md="4" lg="4">
                <p class="text-left thin">
                  {{ inEEC(country) ? "VAT Base" : "Sub-Total" }}
                </p>
              </v-col>
              <v-col cols="6" sm="4" md="4" lg="3" class="price" style="width: 9%">
                <p class="thin d-flex justify-content-end text-right" v-if="vatBlock">
                  {{ currency }} {{ currency == "USD" ? vatBaseUSD : getTowDigitsAfterDot(vatBaseEUR) }}
                </p>
                <p class="thin d-flex justify-content-end text-right" v-else>
                  {{ currency }} {{ currency == "USD" ? priceUSD : priceEUR }}
                </p>
              </v-col>
            </v-row>
  
            <v-row class="mt-n10">
              <v-col cols="6" sm="6" md="4" lg="4">
                <p class="text-left thin">{{ inEEC(country) ? "VAT 20%" : "Tax" }}</p>
              </v-col>
              <v-col cols="6" sm="4" md="4" lg="3" class="price" style="width: 100%">
                <p class="thin d-flex justify-content-end">
                  {{ currency }} {{ currency == "USD" ? percentUSD : percentEUR }}
                </p>
              </v-col>
            </v-row>
  
            <v-row class="mt-n8">
              <v-col cols="6" sm="6" md="4" lg="4">
                <p class="text-left"><strong>Total purchase</strong></p>
              </v-col>
              <v-col cols="6" sm="4" md="4" lg="3">
                <p style="border: 1px solid" class="px-4">
                  {{ currency }} {{ currency == "USD" ? totalUSD : getTowDigitsAfterDot(totalEUR) }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </div>
    </v-sheet>
  
    <v-sheet height="100%" class="mx-auto">
      <div class="container">
        <v-row align="center" justify="center" class="pt-2">
          <v-col cols="12" md="12" v-if="loader">
            <p class="cyan--text text--darken-1 font-weight-bold" id="title">
              CONFIRMATION IN PROCESS...
            </p>
          </v-col>
          <v-col cols="10" sm="4" md="3" lg="3" v-else>
            <v-card-actions class="justify-center">
              <v-btn
                id="btn-photo"
                class="white--text"
                style="font-weight: bold; font-size: 24px"
                color="#514d7a"
                height="70"
                min-width="15"
                rounded
                block
                :disabled="false"
                @click.prevent.stop="payWithPaypal()"
              >
                {{ confirmBtnText }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </div>
        <v-col>
          <p class="blue-grey--text text--lighten-2 font-weight-thin" v-if="totalUSD != 0 || totalEUR != 0">
            Note that you can pay directly with your credit card<br />
            or with Paypal.
          </p>
        </v-col>
      </v-sheet>
    </div>
  </template>
  
  <script>
  import Var from '@/store/services/var.js'
  import Paypal from "@/store/services/paypal.service";
  
  export default {
    props: {
      address: Object,
      product_price: {
        typeof: Number,
        default: 0
      },
      defaultCurrency: {
        typeof: String,
        default: "EUR"
      },
      pitch_deck_info: Object,
      pitch_file: {
        typeof: File,
        default: null
      },
      pitch_deck_url: {
        typeof: String,
        default: null
      },
    },
  
    data() {
      return {
        CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
        country: "France",
        fundRaisingAmountUSD: 0,
        fundRaisingAmountEUR: 0,
        currencies: ["EUR", "USD"],
        change: null,
        currency: "",
        vat: "",
        vatEnabled: 0,
        convertedFile: null,
        EEC: [
          "Austria",
          "Belgium",
          "Bulgaria",
          "Croatia",
          "Cyprus",
          "Czech Republic",
          "Denmark",
          "Estonia",
          "Finland",
          "France",
          "France, Metropolitan",
          "Germany",
          "Greece",
          "Hungary",
          "Ireland",
          "Italy",
          "Latvia",
          "Lithuania",
          "Luxembourg",
          "Malta",
          "Netherlands",
          "Poland",
          "Portugal",
          "Romania",
          "Slovakia",
          "Slovenia",
          "Spain",
          "Sweden",
        ],
        priceUSD: 0,
        priceEUR: 0,
        percentUSD: 0,
        percentEUR: 0,
        totalUSD: 0,
        totalEUR: 0,
        promo_code: '',
        message: '',
        is_correct: false,
        is_not_correct: false,
        userRole: '',
        discount: null,
        discountValueUSD: 0,
        discountValueEUR: 0,
        confirmBtnText: 'PAY NOW',
        vatBlock: false,
        vatBaseUSD: 0,
        vatBaseEUR: 0,
        TotalpriceUSD: 0,
        TotalpriceEUR: 0,
        loader: false,
        user: {
          id: null
        },
        subscriber_end_date: null,
      };
    },
  
    watch: {
      promo_code() {
        if (this.promo_code === '') {
          this.message = '';
        } else if (this.promo_code !== '' && this.promo_code.trim().length >= 8) {
          this.checkIfExist();
        } else {
          this.message = 'The code you have entered does not exist.';
          this.is_not_correct = true
          this.is_correct = false
          if (this.promo_code.trim().length == 7) {
            this.getNeedService(this.change);
          }
        }
      }
    },
  
    methods: {
      checkIfExist() {
        this.message = 'Code you have entered does not exist';
        Paypal.checkCodeAnonymousUser({
        code: this.promo_code,
        role: 'Company',
        product: 'PitchDeck'
      }).then((res)=>{
          if(res.status == 200) {
            this.is_not_correct = false
            this.is_correct = true;
            this.discount = res.data;
            this.computerAmoutToBePayed();
            this.message = ""
            this.$toast.open({
              message: "Code is correct!",
              type: 'success',
              position: 'top-right'
            });
          } else if (res.status == 202) {
            this.message = res.data;
            this.is_not_correct = true
            this.is_correct = false;
            this.discount = null;
            this.computerAmoutToBePayed();
          }  
          else {
            this.is_not_correct = true
            this.is_correct = false;
            this.message = 'The code you have entered does not exist.';
            this.discount = null;
            this.computerAmoutToBePayed();
          }
        })
      },

      convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
      },

      getDiscount(price, discountPercent) {
        return (price * discountPercent) / 100;
      },

      getToday (date) {
        let today = new Date();
        let endDate = new Date(date);
        if (endDate >= today) {
          let startDate = new Date(endDate);
          let dd = startDate.getDate() + 1;
          let mm = startDate.getMonth() + 1;
          let yyyy = startDate.getFullYear();
          if (dd < 10) {
            dd = '0' + dd;
          }
          if (mm < 10) {
            mm = '0' + mm;
          }
          startDate = `${dd}-${mm}-${yyyy}`;
          return startDate;
        } else {
          let dd = today.getDate();
          let mm = today.getMonth() + 1;
          let yyyy = today.getFullYear();
          if (dd < 10) {
            dd = '0' + dd;
          }
          if (mm < 10) {
            mm = '0' + mm;
          }
          today = `${dd}-${mm}-${yyyy}`;
          return today;
        }
      },

      getTodayPlus12Months(date) {
        let today = new Date();
        let endDate = new Date(date);
        if (endDate >= today) {
          let dd = endDate.getDate() + 1;
          let mm = endDate.getMonth() + 1;
          let yyyy = endDate.getFullYear() + 1;
          if (dd < 10) {
            dd = '0' + dd;
          }
          if (mm < 10) {
            mm = '0' + mm;
          }
          endDate = `${dd}-${mm}-${yyyy}`;
          return endDate;
        } else {
          let dd = today.getDate();
          let mm = today.getMonth() + 1;
          let yyyy = today.getFullYear() + 1;
          if (dd < 10) {
            dd = '0' + dd;
          }
          if (mm < 10) {
            mm = '0' + mm;
          }
          today = `${dd}-${mm}-${yyyy}`;
          return today;
        } 
      },
  
      payWithPaypal() {
        let payItems = {
          currency: this.currency,
          total: this.currency == "USD" ? this.totalUSD : this.totalEUR,
          v: this.vatEnabled,
          role: "Company-PitchDeck",
          tpay: "PitchDeck",
          fee: 0
        }; 

        if (payItems.currency && payItems.total != 0) {
          Paypal.chargeAnonymous(payItems).then((res) => {
            if (res.status == 202) {
              if (this.promo_code) {
                localStorage.promo_code = this.promo_code;
                localStorage.discount_amount = this.currency == 'USD' ? this.discountValueUSD : this.discountValueEUR;
                localStorage.coupon_type = this.discount.coupon_type;
                localStorage.coupon_discount = this.discount.coupon_type == "Percent" ? this.discount.percent_discount : this.discount.amount_discount;
              }
              if (this.vatBlock) {
                localStorage.initial_amount  = this.currency == 'USD' ? this.TotalpriceUSD : this.TotalpriceEUR;
                localStorage.vatBase         = this.currency == 'USD' ? this.vatBaseUSD : this.vatBaseEUR;
              } else {
                localStorage.initial_amount  = this.currency == 'USD' ? this.priceUSD : this.priceEUR;
                localStorage.vatBase         = this.currency == 'USD' ? this.priceUSD : this.priceEUR;
              }
              localStorage.user_billing_address = JSON.stringify(this.address);
              localStorage.company = this.pitch_deck_info.company;
              localStorage.email = this.pitch_deck_info.email;
              localStorage.title = this.pitch_deck_info.title;
              localStorage.name = this.pitch_deck_info.name;
              localStorage.phone = this.pitch_deck_info.phone;
              localStorage.pitch_deck_file = this.convertedFile;
              localStorage.pitch_deck_url = this.pitch_deck_url;
              localStorage.item_price = this.currency == 'USD' ? this.fundRaisingAmountUSD : this.fundRaisingAmountEUR;

              window.open(res.data, "_top");
            } else {
              console.log("Error", res);
            }
          });
        } else {
          this.loader = true;
          this.paymentSuccess()
        }
      },
  
      validPayment: function (data, actions) {
        return actions.payment.create({
          transactions: [
            {
              amount: {
                currency: this.currency,
                total: this.currency == "USD" ? this.totalUSD : this.totalEUR,
              },
            },
          ],
        });
      },

      getTowDigitsAfterDot(number) {
        return number.toFixed(2);
      },
  
      computerAmoutToBePayed() {
        this.TotalpriceUSD = 0;
        this.discountValueUSD = 0;
        this.vatBaseUSD = 0;
        this.percentUSD = 0;
        this.percentEUR = 0;
  
        this.fundRaisingAmountUSD = this.product_price;
        this.fundRaisingAmountEUR = this.fundRaisingAmountUSD * this.change;
          
        if ((this.inEEC(this.country) && !this.vat) || this.country == "France" || this.country == "France, Metropolitan" || (this.address.company_name && this.inEEC(this.country) && !this.vat)) {
          this.priceUSD = this.fundRaisingAmountUSD;
          this.priceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
          this.percentUSD = Math.floor(this.priceUSD * 0.2 * 100) / 100;
          this.percentEUR = Math.floor(this.priceEUR * 0.2 * 100) / 100;
          this.vatEnabled = 1;
  
          this.totalUSD = this.priceUSD + this.percentUSD;
          this.totalEUR = this.priceEUR + this.percentEUR;
  
          if (this.is_correct) {
            this.vatBlock = true;
            if(this.discount && this.discount.coupon_type == 'Percent') {
              if(this.discount.percent_discount == 100) {
                this.vatBaseUSD = 0;
                this.percentUSD = 0;
                this.vatBaseEUR = 0;
                this.percentEUR = 0;
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = this.fundRaisingAmountUSD;
                  this.discountValueUSD = this.priceUSD;
                } else {
                  this.discountValueEUR = this.priceEUR;
                  this.TotalpriceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
                } 
              } else {
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = this.fundRaisingAmountUSD;
                  this.discountValueUSD = this.getDiscount(this.priceUSD, this.discount.percent_discount);
                  this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
                  this.percentUSD = Math.floor(this.vatBaseUSD * 0.2 * 100) / 100;
                } else {
                  this.TotalpriceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
                  this.discountValueEUR = this.getDiscount(this.priceEUR, this.discount.percent_discount);
                  this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
                  this.percentEUR = Math.floor(this.vatBaseEUR * 0.2 * 100) / 100;
                }
              } 
            } else if (this.discount && this.discount.coupon_type == 'Value') {
              this.discountValueUSD = this.discount.amount_discount;
              this.discountValueEUR = this.priceEUR = Math.floor(this.discount.amount_discount * this.change * 100) / 100;
              this.TotalpriceUSD = this.fundRaisingAmountUSD;
              this.TotalpriceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
              this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
              this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
              this.percentUSD = Math.floor(this.vatBaseUSD * 0.2 * 100) / 100;
              this.percentEUR = Math.floor(this.vatBaseEUR * 0.2 * 100) / 100;
            }
            this.totalUSD = this.vatBaseUSD + this.percentUSD;
            this.totalEUR = this.vatBaseEUR + this.percentEUR;
          }
  
          if (this.totalUSD == 0 && this.totalEUR == 0) {
            this.confirmBtnText = "CONFIRM"
          } else {
            this.confirmBtnText = "PAY NOW"
          }
  
        } else if (this.inEEC(this.country) && this.vat) {
          this.priceUSD = this.fundRaisingAmountUSD;
          this.priceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
          this.percentUSD = 0;
          this.percentEUR = 0;
  
          this.totalUSD = this.priceUSD + this.percentUSD;
          this.totalEUR = this.priceEUR + this.percentEUR;
  
          if (this.is_correct) {
            this.vatBlock = true;
            if(this.discount && this.discount.coupon_type == 'Percent') {
              if(this.discount.percent_discount == 100) {
                this.vatBaseUSD = 0;
                this.percentUSD = 0;
                this.vatBaseEUR = 0;
                this.percentEUR = 0;
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = this.fundRaisingAmountUSD;
                  this.discountValueUSD = this.priceUSD;
                } else {
                  this.discountValueEUR = this.priceEUR;
                  this.TotalpriceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
                } 
              } else {
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = this.fundRaisingAmountUSD;
                  this.discountValueUSD = this.getDiscount(this.priceUSD, this.discount.percent_discount);
                  this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
                  this.percentUSD = 0;
                } else {
                  this.TotalpriceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
                  this.discountValueEUR = this.getDiscount(this.priceEUR, this.discount.percent_discount);
                  this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
                  this.percentEUR = 0;
                }
              }
            } else if (this.discount && this.discount.coupon_type == 'Value') {
              this.discountValueUSD = this.discount.amount_discount;
              this.discountValueEUR = this.priceEUR = Math.floor(this.discount.amount_discount * this.change * 100) / 100;
              this.TotalpriceUSD = fundRaisingAmountUSD;
              this.TotalpriceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
              this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
              this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
              this.percentUSD = Math.floor(this.vatBaseUSD * 0.2 * 100) / 100;
              this.percentEUR = Math.floor(this.vatBaseEUR * 0.2 * 100) / 100;
            }
            this.totalUSD = this.vatBaseUSD + this.percentUSD;
            this.totalEUR = this.vatBaseEUR + this.percentEUR;
          }
  
          if (this.totalUSD == 0 && this.totalEUR == 0) {
            this.confirmBtnText = "CONFIRM"
          } else {
            this.confirmBtnText = "PAY NOW"
          }
          
        } else {
          this.priceUSD = this.fundRaisingAmountUSD;
          this.priceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
          this.percentUSD = 0;
          this.percentEUR = 0;

          this.totalUSD = this.priceUSD + this.percentUSD;
          this.totalEUR = this.priceEUR + this.percentEUR;
          
          if (this.is_correct) {
            this.vatBlock = true;
            if(this.discount && this.discount.coupon_type == 'Percent') {
              if(this.discount.percent_discount == 100) {
                this.vatBaseUSD = 0;
                this.percentUSD = 0;
                this.vatBaseEUR = 0;
                this.percentEUR = 0;
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = this.fundRaisingAmountUSD;
                  this.discountValueUSD = this.priceUSD;
                } else {
                  this.discountValueEUR = this.priceEUR;
                  this.TotalpriceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
                } 
              } else {
                if (this.currency == 'USD') {
                  this.TotalpriceUSD = this.fundRaisingAmountUSD;
                  this.discountValueUSD = this.getDiscount(this.priceUSD, this.discount.percent_discount);
                  this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
                  this.percentUSD = 0;
                } else {
                  this.TotalpriceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
                  this.discountValueEUR = this.getDiscount(this.priceEUR, this.discount.percent_discount);
                  this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
                  this.percentEUR = 0;
                }
              }
            } else if (this.discount && this.discount.coupon_type == 'Value') {
              this.discountValueUSD = this.discount.amount_discount;
              this.discountValueEUR = this.priceEUR = Math.floor(this.discount.amount_discount * this.change * 100) / 100;
              this.TotalpriceUSD = this.fundRaisingAmountUSD;
              this.TotalpriceEUR = Math.floor(this.priceUSD * this.change * 100) / 100;
              this.vatBaseUSD = this.TotalpriceUSD - this.discountValueUSD;
              this.vatBaseEUR = this.TotalpriceEUR - this.discountValueEUR;
              this.percentUSD = 0;
              this.percentEUR = 0;
            }
            this.totalUSD = this.vatBaseUSD + this.percentUSD;
            this.totalEUR = this.vatBaseEUR + this.percentEUR;
          } else {
            this.vatBlock = false;
          }
  
          if (this.totalUSD == 0 && this.totalEUR == 0) {
            this.confirmBtnText = "CONFIRM";
          } else {
            this.confirmBtnText = "PAY NOW"
          }
        }
      },
  
      paymentSuccess() {
        this.loader = true;
        let data = new FormData();

        data.append("company", this.pitch_deck_info.company);
        data.append("email", this.pitch_deck_info.email);
        data.append("title", this.pitch_deck_info.title);
        data.append("name", this.pitch_deck_info.name);
        data.append("phone", this.pitch_deck_info.phone);
        data.append("token", "NULL");
        data.append("PayerID", "NULL");
        data.append("v", "NULL");
        data.append("role", "Company-PitchDeck");
        data.append("tpay", "PitchDeck");
        data.append("fee", 0);
        data.append("currency", this.currency);
        data.append("discount_amount", this.currency == 'USD' ? this.discountValueUSD : this.discountValueEUR);
        data.append("initial_amount", this.currency == 'USD' ? this.TotalpriceUSD : this.TotalpriceEUR);
        data.append("vatBase", this.currency == 'USD' ? this.vatBaseUSD : this.getTowDigitsAfterDot(this.vatBaseEUR));
        data.append("promo_code", this.promo_code);
        data.append("coupon_type", this.discount.coupon_type);
        data.append("item_price", this.currency == 'USD' ? this.fundRaisingAmountUSD : this.fundRaisingAmountEUR);
        data.append("coupon_discount", this.discount.coupon_type == "Percent" ? this.discount.percent_discount : this.discount.amount_discount);
        data.append("description", "PitchDeck Payment");
        data.append("user_billing_address", JSON.stringify(this.address));
        data.append("pitch_deck_file", this.pitch_file);
        data.append("pitch_deck_url", this.pitch_deck_url);

        Paypal.productPromoCodeSuccess(data).then((res) => {
          if (res.status == 200) {
            localStorage.removeItem("discount_amount");
            localStorage.removeItem("initial_amount");
            localStorage.removeItem("vatBase");
            localStorage.removeItem("coupon_type");
            localStorage.removeItem("coupon_discount");
            this.$emit('successPayment');
          }else {
            this.loader = false;
          }
          this.loader = false;
        }).catch((err) => {
          this.loader = false;
        });
      },

      inEEC (country) {
        return this.EEC.includes(country);
      },
    },
  
    mounted() {
      if(this.pitch_file) {
        this.convertFileToBase64(this.pitch_file)
        .then(base64 => {
          this.convertedFile = base64;
        })
        .catch(error => {
          console.log(error);
        });
      } else {
        this.convertedFile = null;
      }
      
      Var.getRate().then((res) => { 
        this.change = res.data.final_rate; 
        if (this.change) {
          this.computerAmoutToBePayed();
      }})
      this.vat = this.address.vat;
      if (this.address.country) {
        if (this.inEEC(this.address.country)) {
          this.currency = 'EUR';
        } else {
          this.currency = 'USD';
        } 
      } else {
        this.currency = this.defaultCurrency;
      }
      this.country = this.address.country;      
    },
  };
  </script>
  <style>
  @media (max-width: 959px) {
    .bloc-mt-md-n9 {
      margin-top: -35px !important;
    }
    .price {
      width: 100% !important;
    } 
  }
  @media (min-width: 2423px) and (max-width: 2478px) { 
    .price {
      width: 7% !important;
    }
  }
  @media (min-width: 423px) and (max-width: 1555px) { 
    .price {
      width: 11% !important;
    }
  }
  @media (min-width: 1129px) and (max-width: 1189px) { 
    .price {
      width: 14% !important;
    }
  }
  @media (min-width: 1129px) and (max-width: 1445px) { 
    .price {
      width: 11% !important;
    }
  }
</style>
  