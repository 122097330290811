<template>
  <div>
    <v-row>
      <v-col cols="10">
        <p class="pt-2 text-sm-left cyan--text text--darken-1" id="title">
          Your Billing Address
        </p>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="12" lg="12" style="background-color: #00acc1">
        <v-row>
          <v-col cols="1" />
          <v-col cols="10">
            <v-row class="mt-2">
              <!-- Gender -->
              <v-col cols="5" md="2" class="mt-1">
                <v-autocomplete
                  v-model="billingAddress.gender"
                  :items="allGenre"
                  background-color="white"
                  outlined
                  dense
                />
              </v-col>

              <!-- First Name -->
              <v-col cols="7" md="5">
                <FormInlineMessage
                  v-if="$v.billingAddress.first_name.$error"
                  class="errmsg"
                >
                  Please fill the field
                </FormInlineMessage>
                <v-text-field
                  class="mt-1"
                  v-model="billingAddress.first_name"
                  background-color="white"
                  outlined
                  dense
                  placeholder="First Name"
                />
              </v-col>

              <!-- Last Name -->
              <v-col cols="12" md="5">
                <FormInlineMessage
                  v-if="$v.billingAddress.last_name.$error"
                  class="errmsg"
                >
                  Please fill the field
                </FormInlineMessage>
                <v-text-field
                  class="mt-1"
                  v-model="billingAddress.last_name"
                  background-color="white"
                  outlined
                  dense
                  placeholder="Last Name"
                />
              </v-col>
            </v-row>

            <v-row class="mt-n9">
              <!-- Company Name -->
              <v-col cols="12">
                <v-text-field
                  v-model="billingAddress.company_name"
                  background-color="white"
                  outlined
                  dense
                  placeholder="Company"
                />
              </v-col>
            </v-row>

            <v-row class="mt-n9">
              <!-- Street -->
              <v-col cols="12">
                <FormInlineMessage
                  v-if="$v.billingAddress.street.$error"
                  class="errmsg"
                >
                  Please fill the field
                </FormInlineMessage>
                <v-text-field
                  v-model="billingAddress.street"
                  background-color="white"
                  outlined
                  dense
                  placeholder="Street"
                />
              </v-col>
            </v-row>

            <v-row class="mt-n9">
              <!-- City -->
              <v-col cols="12" md="6">
                <FormInlineMessage
                  v-if="$v.billingAddress.city.$error"
                  class="errmsg"
                >
                  Please fill the field
                </FormInlineMessage>
                <v-text-field
                  v-model="billingAddress.city"
                  background-color="white"
                  outlined
                  dense
                  placeholder="City"
                />
              </v-col>

              <!-- ZIP Code -->
              <v-col cols="12" md="6">
                <FormInlineMessage
                  v-if="$v.billingAddress.zip_code.$error"
                  class="errmsg"
                >
                  Please fill the field
                </FormInlineMessage>
                <v-text-field
                  v-model="billingAddress.zip_code"
                  background-color="white"
                  outlined
                  dense
                  placeholder="Zip Code"
                />
              </v-col>
            </v-row>

            <v-row class="mt-n9">
              <!-- Country -->
              <v-col cols="12" md="7" class="mb-2">
                <FormInlineMessage
                  v-if="$v.billingAddress.country.$error"
                  class="errmsg"
                >
                  Please fill the field
                </FormInlineMessage>
                <v-select
                  v-model="billingAddress.country"
                  :items="countries"
                  background-color="white"
                  outlined
                  dense
                  placeholder="Country"
                />
              </v-col>
            </v-row>

            <v-row class="mt-n9">
              <!-- VAT -->
              <v-col cols="12">
                <v-text-field
                  v-model="billingAddress.vat"
                  background-color="white"
                  outlined
                  dense
                  placeholder="VAT #########"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-col />
    <v-col />
    <v-col />
  
      <div height="100%" class="mx-auto mt-n15" v-if="currentBlock">
        <v-row align="center" justify="center">
          <v-col />
          <v-col cols="12">
            <v-icon
              large
              color="#00aac3"
              right
              center
              @click.prevent.stop="validAddress()"
            >
              mdi-arrow-down-circle
            </v-icon>
          </v-col>
        </v-row>
    </div>
    </div>
  </template>
  
  <script>
  import Var from "@/store/services/var";
  import FormInlineMessage from "@/components/FormInlineMessage";
  
  import { required } from "vuelidate/lib/validators";
  
  export default {
    components: {
      FormInlineMessage,
    },
  
    data() {
      return {
        currentBlock: true,
        allGenre: ["Mr.", "Ms.", "Mrs."],
        countries: [],
        billingAddress: {
          gender: "Ms.",
          first_name: "",
          last_name: "",
          company_name: "",
          street: "",
          city: "",
          zip_code: "",
          country: "",
          vat: "",
        },
        is_individual: false,
      };
    },
  
    validations: {
      billingAddress: {
        required,
        first_name: { required },
        last_name: { required },
        street: { required },
        city: { required },
        zip_code: { required },
        country: { required },
      },
    },
  
    methods: {
      validAddress() {
        this.$v.$touch();
        if (this.$v.$error) {
          return;
        }
       
        this.currentBlock = false;
        this.$emit("block3", this.billingAddress);
      },
    },
  
    mounted() {
      Var.getAllCountry().then((res) => {
        this.countries = res.data;
      });
    },
  };
  </script>
  