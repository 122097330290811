<template>
    <div class="main-bloc">
      <HeaderSponsor class="d-none d-md-flex" />
      <HeaderSponsorPortable class="d-flex d-md-none" />
      
      <div class="containe">
        <div class="mt-15 prod-first-bloc">
          <h2 class="site-color">Our products and services</h2>receive-invoice.png
          <p>Because we want the best for you, we are offering assistance for companies who need feedback on their Pitch Deck or advice before fundraising.</p>
        </div>
  
        <div class="bg-light mt-3 justify-content-center">
          <div class="container">
            <div class="row col-gap justify-content-center d-grid gap-3">
              <div class="col-12 col-md-4 ms-lg-3 p-0 bg-info text-white">
                <div class="p-3 descriptive-img">
                  <h3>Make your Pitch Deck legible, simple and obvious</h3>
                </div>
                <div class="bg-info p-0 m-0">
                  <img src="../../assets/img/IdeasFundX_pitchdeck.png" alt="Get Feedback for your pitch deck" class="w-100" />
                </div>
              </div>
  
              <div class="col-12 col-md-6 ms-lg-3 p-10 bg-site text-left">
                <div class="p-2">
                  <p><strong class="normal-text">Get Feedback for your Pitch Deck</strong></p>
                  <p class="normal-text">Feedback Perks</p>
                  <ul>
                    <li>Get Practical and actionable deck feedback</li>
                  </ul>
                  <p class="normal-text">$300 excl. tax per deck</p>
                  <div class="text-center d-block d-sm-none" @click="showIcones = !showIcones">
                    <img src="../../assets/img/showMore.png" alt="Show more" width="40" />
                  </div>
                </div>
              </div>
            </div>
            <!-- web -->
            <div class="d-none d-sm-block">
              <div class="d-sm-flex align-items-around justify-content-around mt-7">
                <div>
                  <img src="../../assets/img/attach-pitch-deck.png" alt="Attach your Pitch Deck" width="43" class="cursor-pointer">
                  <p>1 <br> Send your pitch deck</p>
                </div>
                <div>
                  <img src="../../assets/img/receive-invoice.png" alt="Your invoice for Pitch Deck Feedback" width="45" class="cursor-pointer">
                  <p>2 <br> Receive the invoice</p>
                </div>
                <div>
                  <img src="../../assets/img/pay-fees.png" alt="Pay the Pitch Deck Fees" width="52" class="cursor-pointer">
                  <p>3 <br> Pay</p>
                </div>
                <div>
                  <img src="../../assets/img/get-feedbacks.png" alt="Get Pitch Deck Feedback" width="50" class="cursor-pointer">
                  <p>4 <br> Get our feedbacks <br> (1/2 pages insights report)</p>
                </div>
              </div>
            </div>
  
            <!-- mobile -->
            <div class="d-block d-sm-none hide-feedBackBloc" :class="{ 'show-feedBackBloc': showIcones }">
              <div class="d-sm-flex align-items-around justify-content-around mt-7">
                <div class="row justify-content-around">
                  <div class="col-6">
                    <img src="../../assets/img/attach-pitch-deck.png" alt="Attach your Pitch Deck" width="43" class="cursor-pointer">
                    <p>1 <br> Send your pitch deck</p>
                  </div>
                  <div class="col-6">
                    <img src="../../assets/img/receive-invoice.png" alt="Your invoice for Pitch Deck Feedback" width="45" class="cursor-pointer">
                    <p>2 <br> Receive the invoice</p>
                  </div>
                </div>
                <div class="row justify-content-around">
                  <div class="col-6">
                    <img src="../../assets/img/pay-fees.png" alt="Pay the Pitch Deck Fees" width="52" class="cursor-pointer">
                    <p>3 <br> Pay</p>
                  </div>
                  <div class="col-6">
                    <img src="../../assets/img/get-feedbacks.png" alt="Get Pitch Deck Feedback" width="50" class="cursor-pointer">
                    <p>4 <br> Get our feedbacks <br> (1/2 pages insights report)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-4 text-white" style="font-weight: bold; font-size: 18px; background-color: #514d7a;">Submit your pitch deck</div>
        <div class="bg-light pt-10">
          <div class="container">
            <v-row class="mb-n8">
              <v-col cols="12" sm="2">
                <FormInlineMessage class="text-danger" v-if="$v.pitch_deck_info.email.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">E-mail Address*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.pitch_deck_info.email.$error">
                  Please enter your email address
                </FormInlineMessage>
                <v-text-field
                  v-model="pitch_deck_info.email"
                  outlined
                  placeholder="Your Email Address"
                  dense
                  @blur="$v.pitch_deck_info.email.$touch()"
                />
              </v-col>
            </v-row>
  
            <v-row class="mb-n8">
              <v-col cols="12" sm="2">
                <FormInlineMessage v-if="$v.pitch_deck_info.name.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">Your name*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.pitch_deck_info.name.$error">
                  Please enter your name
                </FormInlineMessage>
                <v-text-field
                  v-model="pitch_deck_info.name"
                  outlined
                  placeholder="Your Name"
                  dense
                  @blur="$v.pitch_deck_info.name.$touch()"
                />
              </v-col>
            </v-row>
  
            <v-row class="mb-n8">
              <v-col cols="12" sm="2">
                <FormInlineMessage v-if="$v.pitch_deck_info.title.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">Title*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.pitch_deck_info.title.$error">
                  Please enter your title
                </FormInlineMessage>
                <v-text-field
                  v-model="pitch_deck_info.title"
                  outlined
                  placeholder="Your Title"
                  dense
                  @blur="$v.pitch_deck_info.title.$touch()"
                />
              </v-col>
            </v-row>
  
            <v-row class="mb-n8">
              <v-col cols="12" sm="2">
                <FormInlineMessage v-if="$v.pitch_deck_info.company.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">Company*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.pitch_deck_info.company.$error">
                  Please enter your company
                </FormInlineMessage>
                <v-text-field
                  v-model="pitch_deck_info.company"
                  outlined
                  placeholder="Company"
                  dense
                  @blur="$v.pitch_deck_info.company.$touch()"
                />
              </v-col>
            </v-row>
  
            <v-row>
              <v-col cols="12" sm="2">
                <FormInlineMessage v-if="$v.phoneValid.$error">
                  <v-col cols="0" sm="12"/>
                </FormInlineMessage>
                <p class="text-sm-left">Phone*</p>
              </v-col>
              <v-col cols="12" sm="8">
                <FormInlineMessage class="text-danger" v-if="$v.phoneValid.$error">
                  Please select valid phone number
                </FormInlineMessage>
                <vue-tel-input
                defaultCountry="country"
                v-model="pitch_deck_info.phone"
                @validate="validatePhone"/>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="bg-light mt-10 pt-5">
          <div class="container">
            <strong>Attach your Pitch Deck</strong>
            <v-col cols="12" md="10">
              <v-radio-group v-model="withFileUrl" class="" style="position: relative; height: auto;">
                <v-row>
                  <v-radio :value="0" color="#11aec9"/>
                  <p class="text-left mt-3 d-block d-sm-none">Upload your  pitch deck</p>
                  <div class="mt-3 d-flex justify-content-center align-items-center">
                    <div>
                      <span class="text-left d-none d-sm-block">Upload your  pitch deck</span>
                      <span class="blue-grey--text text--lighten-3">Max 5Mb </span>
                    </div>
                    <div class="ml-10 text-center"> 
                      <label 
                        for="fileButton"
                        :class="{'disabled': withFileUrl == 1}" 
                        class="d-flex justify-content-center align-items-center white--text pl-10 pr-10 pt-2 pb-2 cursor-pointer"
                        style="font-weight: bold; font-size: 18px; background-color: #514d7a;  border-radius: 70px;">
                        <img src="../../assets/img/uploaFile.png" alt="Attach your Pitch Deck" width="35" height="35" class="cursor-pointer"> 
                        <span class="ml-3">UPLOAD</span>
                      </label>
                      <input type="file" id="fileButton" style="display: none;" @change="handleFileUpload()" ref="file" v-if="withFileUrl == 0" />
                    </div>
                  </div>
                </v-row>

                <v-row>  
                  <v-row class="mt-3">
                    <v-col cols="12" sm="12">
                      <div class="d-flex">
                        <v-radio :value="1" color="#11aec9"/>
                        <p class="text-sm-left">
                          Link to pitch deck <br> 
                          <span class="blue-grey--text text--lighten-3">Please include a deck that allows access without login</span>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="12" class="mt-n8">
                      <v-text-field
                        v-model="pitch_deck_url"
                        outlined
                        placeholder="Enter the link to your pitch deck"
                        :disabled="withFileUrl == 0 ? true : false"
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-row>
              </v-radio-group>
            </v-col>

            <v-icon
              large
              color="#00aac3"
              right
              center
              @click.prevent.stop="savePitchForm()"
              v-if="!addresBloc"
            >
              mdi-arrow-down-circle
            </v-icon>
          </div>
        </div>

        <div class="bg-light mt-10" v-if="addresBloc">
          <div class="container">
            <AddressBlock @block3="getBillingAddress" class="w-100"/>
          </div>
        </div>
        <PriceBlock 
          v-if="priceBlock"
          :product_price="product_price"
          :address="billing_address"
          :defaultCurrency=" true ?  'EUR' : 'USD'"
          :pitch_deck_info="pitch_deck_info"
          :pitch_file="file"
          :pitch_deck_url="pitch_deck_url"
          @successPayment="successPayment"
        />

        <v-dialog v-model="dialogPaymentSuccess" max-width="500px">
            <v-card
                min-height="200px"
                style="overflow-x: hidden; overflow-y: hidden"
            >
                <v-card-title class="justify-center">
                    <span v-if="!loader" id="title">PAYMENT CONFIRMED</span>
                    <span v-else id="title">PAYMENT IN PROGRESS</span>
                </v-card-title>
                <v-sheet height="100%" class="mx-3 my-0" color="#c5e7ec" rounded>
                    <v-card-text>
                        <v-container>
                            <v-row class="text-left sub-title-code mt-n5">
                                <p v-if="!loader">
                                    Check your mailbox, you have received
                                    an email with your invoice, and 
                                    IdeasFundX team will start the review of 
                                    your pitch deck.
                                </p>
                                <p v-else class="cyan--text text--darken-1 font-weight-bold text-center p-3" id="title">
                                  CONFIRMATION IN PROCESS...
                                </p>
                            </v-row>
                            <v-row v-if="!loader"  class="text-left sub-title-code mt-n2 mb-n9">
                              <p><br>For any question, please contact us. <a v-bind:href="CMScontact">contact us</a></p>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-sheet>
                <div class="d-flex justify-content-between align-items-center p-3" v-if="!loader">
                    <div class="btn-ruturn-home d-flex justify-content-center align-items-center mt-n3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" size="20" fill="currentColor" class="bi bi-chevron-left mr-2 mt-3" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        <div style="font-size: 22px" class="mt-3">Home</div>
                    </div>
                    <v-btn
                        id="btn-verify"
                        center
                        class="white--text my-3"
                        color="#11aec9"
                        height="50"
                        min-width="120"
                        rounded
                        @click.stop.prevent="dialogPaymentSuccess = false"
                        :disabled="false"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg> OK
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPaymentFail" max-width="500px">
            <v-card
                min-height="200px"
                style="overflow-x: hidden; overflow-y: hidden"
            >
                <v-card-title class="justify-center">
                    <span id="title">INTERRUPTION <br> OF YOUR PAYMENT</span>
                </v-card-title>
                <v-sheet height="100%" class="mx-3 my-0" color="#c5e7ec" rounded>
                    <v-card-text>
                        <v-container>
                            <v-row class="text-left sub-title-code mt-n5">
                                <p>
                                    Paypal informed us that the transaction of 
                                    your payment was interrupted.</p>
                            </v-row>
                            <v-row  class="text-left sub-title-code mt-n2 mb-n9">
                            <p><br>For any question, please contact us. <a v-bind:href="CMScontact">contact us</a></p>
                            </v-row>

                        </v-container>
                    </v-card-text>
                </v-sheet>
                <div class="d-flex justify-content-between align-items-center p-3">
                    <div class="btn-ruturn-home">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" size="20" fill="currentColor" class="bi bi-chevron-left mr-2 mt-3" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        <span @click="dialogPaymentFail = false" style="font-size: 22px">BACK</span>
                    </div>
                    <v-btn
                        id="btn-verify"
                        center
                        class="white--text my-3"
                        color="#11aec9"
                        height="50"
                        min-width="120"
                        rounded
                        :disabled="false"
                        @click="addresBloc = true, dialogPaymentFail = false"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg> TRY AGAIN
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
      </div>
  
      <Footer />
    </div>
  </template>
  
  <script>
    import Footer from "@/components/FooterCms.vue";
    import HeaderSponsor from "@/components/HeaderSponsor.vue";
    import HeaderSponsorPortable from "@/components/HeaderSponsorPortable.vue";
    import FormService from "@/store/services/contact.service.js";
    import {
      minLength,
      email,
      sameAs,
      required,
    } from "vuelidate/lib/validators";
    import AddressBlock from '../../views/products/AddressBlock.vue'
    import PriceBlock from '../../views/products/PriceBlock.vue';
    import Paypal from "@/store/services/paypal.service";
    import Var from '@/store/services/var.js';
  
  export default {
    components: {
      Footer,
      HeaderSponsor,
      HeaderSponsorPortable,
      AddressBlock,
      PriceBlock
    },
    metaInfo: {
      title: 'IDEASFUNDX | Get Feedback for your Pitch Deck',
      meta: [
        {
          name: "description",
          content:
            "Advices to Make your Pitch Deck legible, simple and obvious.",
        },
        {
          name: "keywords",
          content:
            "secure fundraising, improve pitch deck, review business plan, define go-to-market strategy"
        },
        {
          property: "og:image",
          content: "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          property: "og:image:width",
          content: "1200",
        },
        {
          property: "og:image:height",
          content: "630",
        },
        {
          property: "og:locale",
          content: "en_US",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          property: "og:description",
          content: "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the deal flow screening process for high quality deals.",
        },
        {
          property: "og:url",
          content: "https://www.ideasfundx.com/",
        },
        {
          property: "og:site_name",
          content: "IdeasFundX",
        },
        {
          name: "twitter:card",
          content:
            "summary_large_image",
        },
        {
          name: "twitter:title",
          content:
            "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
        },
        {
          name: "twitter:description",
          content:
            "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the dealflow screening process for high quality deals.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
        },
        {
          name: "twitter:image:width",
          content:
            "1200",
        },
        {
          name: "twitter:image:height",
          content:
            "630",
        },
      ]
    },
    data() {
      return {
        CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
        addresBloc: false,
        priceBlock: false,
        withFileUrl: 0,
        pitch_deck_url: null,
        loader: false,
        product_price: 0,
        pitch_deck_info : {
          email: null,
          name: null,
          title: null,
          phone: null,
          company: null,
        },
        pitch_deck_generated: null,
        showIcones: false,
        phoneValid: false,
        file: null,
        billing_address: null,
        dialogPaymentSuccess: false,
        dialogPaymentFail: false,
      };
    },
  
    validations: {
      phoneValid: { sameAs: sameAs(() => true) },
      pitch_deck_info : {
        email: { required, email },
        name: { required, minLength: minLength(2) },
        title: { required },
        phone: { required },
        company: { required },
      },
    },
  
    methods: {
      getBillingAddress(payload) {
        this.billing_address = payload;
        this.priceBlock = true;
      },
      handleFileUpload() {
        let file = this.$refs.file.files[0];
        if (file.type.split("/")[1] != "pdf") {
          this.$toast.open({
            message: "Please upload a pdf file !",
            type: 'error',
            position: 'top-right'
          });
          // file should have a size less than 5 MB
        } else if (file.size > 5000000) {
          this.$toast.open({
            message: "Please choose a deck with a maximum size of 5 MB !",
            type: 'error',
            position: 'top-right'
          });
        } else {
          this.file = this.$refs.file.files[0];
        }
      },

      savePitchForm() {
        this.submitPitchDeckForm();
      },

      validatePhone(params) {
        this.phoneValid = params.valid;
        this.pitch_deck_info.phone = params.number;
      },

      submitPitchDeckForm() {
        this.$v.$touch();
        if (this.$v.$error) {
          return false;
        }

        if (!this.file && !this.pitch_deck_url) {
          this.$toast.open({
            message: "Please upload your pitch deck to get feedback from our team of experts",
            type: 'warning',
            position: 'top-right',
            duration: 5000
          });
          return;
        }

        this.addresBloc = true;

        let data = new FormData();
  
        data.append("email", this.pitch_deck_info.email);
        data.append("name", this.pitch_deck_info.name);
        data.append("title", this.pitch_deck_info.title);
        data.append("company", this.pitch_deck_info.company);
        data.append("phone", this.pitch_deck_info.phone);
        data.append("withFileUrl", this.withFileUrl);
        this.withFileUrl == 0 ? data.append("pitch_deck_file", this.file) : data.append("pitch_deck_file", this.pitch_deck_url);
  
        FormService.submitPitchDeckForm(data)
          .then((res) => {
            // this.pitch_deck_info.email = null;
            // this.pitch_deck_info.name = null;
            // this.pitch_deck_info.title = null;
            // this.pitch_deck_info.company = null;
            // this.pitch_deck_info.phone = null;
            // this.file = null;
            // this.$v.$reset(); //reset errors
            if (this.withFileUrl == 0) {
              this.$toast.open({              
                message: res.data.message,
                type: 'success',
                position: 'top-right',
                duration: 5000
              });
            } 
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response.data.errors[0].title,
              type: 'warning',
              position: 'top-right',
              duration: 5000
            });
          });
      },

      dataURItoBlob(dataURI) {
        let byteString = atob(dataURI.split(',')[1]);
        // let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'pdf' });
      },

      completePayment() {
        let data = new FormData();
        this.loader = true;
        this.dialogPaymentSuccess = true;

        data.append("company", localStorage.company);
        data.append("name", localStorage.name);
        data.append("email", localStorage.email);
        data.append("phone", localStorage.phone);
        data.append("title", localStorage.title);
        data.append("paymentId", this.$route.query.paymentId);
        data.append("token", this.$route.query.token);
        data.append("PayerID", this.$route.query.PayerID);
        data.append("v", this.$route.query.v);
        data.append("role", this.$route.query.role);
        data.append("tpay", this.$route.query.tpay);
        data.append("fee", this.$route.query.fee);
        data.append("item_price", localStorage.item_price ? localStorage.item_price : 0);
        data.append("discount_amount", localStorage.discount_amount ? localStorage.discount_amount : 0);
        data.append("promo_code", localStorage.promo_code ? localStorage.promo_code : null);
        data.append("initial_amount", localStorage.initial_amount ? localStorage.initial_amount : 0);
        data.append("vatBase", localStorage.vatBase ? localStorage.vatBase : 0);
        data.append("coupon_type", localStorage.coupon_type ? localStorage.coupon_type : null);
        data.append("coupon_discount", localStorage.coupon_discount ? localStorage.coupon_discount : null);
        data.append("user_billing_address", localStorage.user_billing_address);
        data.append("description", "Pitch Deck Feedback");
        data.append("pitch_deck_file", this.pitch_deck_generated);
        data.append("pitch_deck_url", localStorage.pitch_deck_url);

        Paypal.productPaymentSuccess(data)
          .then((res) => {
            if (res.status == 200) {
              localStorage.removeItem("item_price");
              localStorage.removeItem("promo_code");
              localStorage.removeItem("discount_amount");
              localStorage.removeItem("initial_amount");
              localStorage.removeItem("vatBase");
              localStorage.removeItem("coupon_type");
              localStorage.removeItem("coupon_discount");
              localStorage.removeItem("user_billing_address");
              localStorage.removeItem("email");
              localStorage.removeItem("name");
              localStorage.removeItem("company");
              localStorage.removeItem("phone");
              localStorage.removeItem("title");
              localStorage.removeItem("pitch_deck_file");
              localStorage.removeItem("pitch_deck_url");
              
              this.$router.push({ name: "PitchDeck" });
              this.dialogPaymentSuccess = true;
              this.loader = false;
            } else {
              this.$toast.open({
                message: "Error happened during payment process please try again or contact us",
                type: 'warning',
                position: 'top-right',
                duration: 5000
              });
              this.dialogPaymentSuccess = false;
              this.loader = false;
            }
          })
          .catch((e) => {
            this.$toast.open({
              message: "Error happened during payment process please try again or contact us",
              type: 'warning',
              position: 'top-right',
              duration: 5000
            });
            this.dialogPaymentSuccess = false;
            this.loader = false;
          });
      },

      successPayment() {
        this.dialogPaymentSuccess = true;
        this.addresBloc = false;
        this.priceBlock = false;
      },
    },
    mounted() {
      Var.getProduct(7).then((res) => { this.product_price = res.data.product_price; }); // get product price id 7 is pitch deck
      if (
        (this.$route.query.token || this.$route.query.ba_token) &&
          this.$route.query.tpay && !this.$route.query.status
        ) {
        if(localStorage.pitch_deck_file && localStorage.pitch_deck_file != "null" && localStorage.pitch_deck_file != null) {
          this.pitch_deck_generated = new File([this.dataURItoBlob(localStorage.pitch_deck_file)], `pitch_deck_${localStorage.company}.pdf`, {type: 'pdf'});
        } else {
          this.pitch_deck_generated = null;
        }

        this.completePayment();
      }
      if (
        (this.$route.query.token || this.$route.query.ba_token) &&
          this.$route.query && this.$route.query.status == "fail"
        ) {
        this.dialogPaymentFail = true;
      } 
    },
  };
  </script>
  
<style scoped>
  @import '../../assets/styles/products.css';
</style>
  