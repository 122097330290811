<template>
  <div>
    <v-sheet height="100%" class="mt-10">
      <div class="container">
        <v-row align="center" justify="center" class="pt-2">
          <v-col cols="1" />
          <v-col cols="12">
            <p class="pt-2 text-sm-left cyan--text text--darken-1" id="title">
              Your Payment Currency
            </p>
            <v-row>
              <v-col cols="12" sm="12" md="5" lg="5">
                <p class="text-left thin pt-2">
                  Confirm the currency of your payment
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="5">
                <v-select
                  v-model="currency"
                  :items="currencies"
                  background-color="white"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="5" lg="5">
                <p class="text-left cyan--text text--darken-1" id="title">
                  Your Time Zone
                </p>
              </v-col>
              
              <v-col cols="12" sm="12" md="5" lg="5">
                <FormInlineMessage v-if="$v.timeZoneValue.$error">
                  Please select your time zone
                </FormInlineMessage>
                <v-select
                  v-model="timeZoneValue"
                  :items="timeZones"
                  background-color="white"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="5" lg="5">
                <p class="text-left cyan--text text--darken-1" id="title">
                  Call duration
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="5">
                <v-select
                  v-model="callDurationValue"
                  :items="callDuration"
                  default-value="30 min"
                  background-color="white"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1" />
        </v-row>
      </div>
    </v-sheet>
  
      <v-sheet height="100%" class="mx-auto mt-10">
        <div class="container">
          <v-row align="center" justify="center" class="pt-2">
            <v-col cols="1" />
            <v-col cols="12">
              <p class="text-sm-left cyan--text text--darken-1" id="title">
                Topic of the consultancy
              </p>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <p class="text-left thin mb-n3">
                    What is the topic you would like to discuss?
                  </p>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <FormInlineMessage v-if="$v.order_consultancy_topic.$error">
                    Please enter the topic
                  </FormInlineMessage>
                    <v-text-field
                      v-model="order_consultancy_topic"
                      background-color="white"
                      :counter="300"
                      outlined
                      dense
                      @blur="$v.order_consultancy_topic.$touch()"
                    />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <p class="text-left thin mb-n3">
                    Is there anything else you would like to share?
                  </p>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-textarea
                    v-model="order_consultancy_description"
                    rows="7"
                    :counter="1500"
                    outlined 
                    dense />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" />
          </v-row>
        </div>
      </v-sheet>
      <div class="d-flex justify-content-center align-items-center mt-5">
        <v-col cols="12" md="12" v-if="loader">
          <p class="cyan--text text--darken-1 font-weight-bold" id="title">
            ORDER CONFIRMATION IN PROCESS...
          </p>
        </v-col>
        <div v-else>
          <v-btn
            id="btn-photo"
            class="white--text"
            style="font-weight: bold; font-size: 24px"
            color="#514d7a"
            height="70"
            min-width="15"
            rounded
            block
            :disabled="false"
            @click="saveConsultancyInfo"
          >
            SCHEDULE
          </v-btn>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import FormService from "@/store/services/contact.service.js";
  import FormInlineMessage from "@/components/FormInlineMessage";
  import { required } from "vuelidate/lib/validators";
  import AllVar from "@/store/services/var";
  
  export default {
    components: {
      FormInlineMessage,
    },
    props: {
      address: Object,
      consultancy_info: Object,
      profile: Object,
      product_price: {
        typeof: Number,
        default: 0
      },
      defaultCurrency: {
        typeof: String,
        default: "EUR"
      },
    },
  
    data() {
      return {
        CMScontact: `${process.env.VUE_APP_CMS_URL}contact`,
        country: "France",
        fundRaisingAmountUSD: 0,
        fundRaisingAmountEUR: 0,
        currencies: ["EUR", "USD"],
        timeZones: [],
        callDuration: ["30 min", "60 min", "90 min", "120 min"],
        callDurationValue: "60 min",
        currency: "",
        vat: "",
        timeZoneValue: null,
        order_consultancy_topic: null,
        order_consultancy_description: null,
        EEC: [
          "Austria",
          "Belgium",
          "Bulgaria",
          "Croatia",
          "Cyprus",
          "Czech Republic",
          "Denmark",
          "Estonia",
          "Finland",
          "France",
          "France, Metropolitan",
          "Germany",
          "Greece",
          "Hungary",
          "Ireland",
          "Italy",
          "Latvia",
          "Lithuania",
          "Luxembourg",
          "Malta",
          "Netherlands",
          "Poland",
          "Portugal",
          "Romania",
          "Slovakia",
          "Slovenia",
          "Spain",
          "Sweden",
        ],
        priceUSD: 0,
        priceEUR: 0,
        percentUSD: 0,
        percentEUR: 0,
        totalUSD: 0,
        totalEUR: 0,
        message: '',
        is_correct: false,
        is_not_correct: false,
        userRole: '',
        discount: null,
        discountValueUSD: 0,
        discountValueEUR: 0,
        confirmBtnText: 'PAY NOW',
        vatBlock: false,
        vatBaseUSD: 0,
        vatBaseEUR: 0,
        TotalpriceUSD: 0,
        TotalpriceEUR: 0,
        loader: false,
        user: {
          id: null
        },
        subscriber_end_date: null,
      };
    },

    validations: {
      order_consultancy_topic: { required },
      timeZoneValue: { required },
    },
  
    methods: {
      inEEC (country) {
        return this.EEC.includes(country);
      },

      saveConsultancyInfo() {
        this.loader = true;
        this.$v.order_consultancy_topic.$touch();
        this.$v.timeZoneValue.$touch();
        if (this.$v.order_consultancy_topic.$error || this.$v.timeZoneValue.$error) {
          this.loader = false;
          return false;
        }

        let data = new FormData();
  
        data.append("email", this.consultancy_info.email);
        data.append("name", this.consultancy_info.name);
        data.append("title", this.consultancy_info.title);
        data.append("company", this.consultancy_info.company);
        data.append("phone", this.consultancy_info.phone);
        data.append("type_of_product", "Consultancy");
        data.append("type_of_profile", "Company");
        data.append("user_billing_address", JSON.stringify(this.address));
        data.append("order_currency", this.currency);
        data.append("order_consultancy_duration", this.callDurationValue);
        data.append("order_consultancy_timeZone", this.timeZoneValue);
        data.append("order_consultancy_topic", this.order_consultancy_topic);
        data.append("order_consultancy_description", this.order_consultancy_description);
  
        FormService.consustancyOrder(data)
          .then((res) => {
            this.$toast.open({              
              message: res.data.message,
              type: res.data.type,
              position: 'top-right',
              duration: 5000
            });
            this.loader = false;
            this.timeZoneValue = null;
            this.order_consultancy_topic = null;
            this.order_consultancy_description = null;
            this.$emit('orderSuccess');
          })
          .catch((e) => {
            this.$toast.open({
              message: "An error has occurred. Try Again",
              type: 'warning',
              position: 'top-right',
              duration: 5000
            });
            this.loader = false;
          });
      },
    },
  
    mounted() {
      AllVar.getTimeZones().then((res) => {
        this.timeZones = res.data;
      });

      this.timeZoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (this.address.country) {
        if (this.inEEC(this.address.country)) {
          this.currency = 'EUR';
        } else {
          this.currency = 'USD';
        } 
      } else {
        this.currency = this.defaultCurrency;
      }
      this.country = this.address.country;      
    },
  };
  </script>
  <style>
  @media (max-width: 959px) {
    .bloc-mt-md-n9 {
      margin-top: -35px !important;
    }
    .price {
      width: 100% !important;
    } 
  }
  @media (min-width: 2423px) and (max-width: 2478px) { 
    .price {
      width: 7% !important;
    }
  }
  @media (min-width: 423px) and (max-width: 1555px) { 
    .price {
      width: 11% !important;
    }
  }
  @media (min-width: 1129px) and (max-width: 1189px) { 
    .price {
      width: 14% !important;
    }
  }
  @media (min-width: 1129px) and (max-width: 1445px) { 
    .price {
      width: 11% !important;
    }
  }
</style>
  